import React, {Link} from 'react';
import {useLocation} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {FirebaseAuth, Provider} from '../components/instances/firebaseAuth';
import {ClipLoader} from "react-spinners";
import signInLogo from '../images/btn_google_signin_dark_normal_web@2x.png';
import {NavBar} from '../components/navbar';
import {Button} from 'react-bootstrap';
const {currentConfig} = require('../components/instances/currentConfig');
const stripePromise = loadStripe(currentConfig.STRIPE.PUBLISHABLE_KEY);

class Checkout extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          priceId: null,
          productName: null,
          productDescription: null,
          productPrice: null,
          isSignedIn: false,
          currentUserEmail: null,
          currentUserDisplayName: null,
          currentUserUid: null,
          currentUserPhotoURL: null,
          loadingComplete: false,
          isPageValid: false,
          paymentLoading: false
        };
    }

    async load(){
      await this.getProductIdAndPriceIdFromURL();
      await this.authChangedEventListener();
      // await this.checkIfUserIsSignedIn();
      this.setState({loadingComplete: true});
    }

    getProductIdAndPriceIdFromURL(){
      return new Promise(async (resolve)=>{
        let location = window.location;
        let query = new URLSearchParams(location.search);
        let priceId = query.get("id");
        if(priceId){
            this.setState({
              priceId: priceId
            });
            await this.validateProductId(priceId);
            resolve();
        }
        else{
            // Page Invalid
            this.setState({
              priceId: null, 
              isPageValid: false
            });
            console.log("Missing 'id' in the Query String");
            resolve();
        }
      })
    }

    validateProductId(priceId){
      return new Promise(async (resolve)=>{
        let queryString = `?priceId=${priceId}`;
        let getProductByIdURL = currentConfig.BACKEND_URL_ROOT + currentConfig.GET_PRODUCT_BY_PRICE_ID_ENDPOINT + queryString;
        let product = await fetch(getProductByIdURL).catch((err)=>{
          console.log(err);
        });
        if(product.status === 200){
          product = await product.json();
          product = product["data"];
          console.log(product);
          this.setState({
            isPageValid: true,
            productName: product["name"],
            productDescription: product["description"],
            productPrice: product["price"]
          });
          resolve();
        }
        else{
          this.setState({
            isPageValid: false,
            productName: null,
            productDescription: null,
            productPrice: null
          });
          resolve();
        }
      })
    }

    async getCheckoutSession(){
      this.setState({paymentLoading: true});
      let priceId = this.state.priceId;
      let email_address = this.state.currentUserEmail;
      // Get Stripe.js instance
      const stripe = await stripePromise;
      const checkout_session_url = currentConfig.BACKEND_URL_ROOT + currentConfig.CREATE_CHECKOUT_SESSION_ENDPOINT;
      // Call backend to create the Checkout Session
      fetch(checkout_session_url, {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'}, 
        body: JSON.stringify({"priceId": priceId, "email_address": email_address})
      })
      .then(async (response)=>{
        const session = await response.json();
        if(session.status == "success"){
          // Redirect the user to Checkout.
          const result = await stripe.redirectToCheckout({
            sessionId: session.data.id,
          });
          this.setState({paymentLoading: false});
          console.log(session.data.id);
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            console.log(result.error);
          }
        }
        else{
          console.log(session.message);
        }
      })
      .catch((err)=>{
        console.log(err);
        this.setState({paymentLoading: false});
      })
    };

    signIn(){
        FirebaseAuth().signInWithPopup(Provider)
        .then((user)=>{
            console.log(user);
        })
        .catch((err)=>{
            console.log(err);
        })
    };

    signOut() {
        FirebaseAuth().signOut()
        .then((out)=>{
            console.log(out);
        })
        .catch((err)=>{
            console.log(err);
        })
    };

    createUserIfRequired(user){
      return new Promise(async (resolve, reject)=>{
        let createUserURL = currentConfig.BACKEND_URL_ROOT + currentConfig.CREATE_USER_IF_REQUIRED_ENDPOINT;
        let payload = {
          "uid": user.uid,
          "email_address": user.email,
          "display_name": user.displayName,
          "photo_url": user.photoURL,
          "tokens": {
              "access_token": await user.getIdToken(),
              "refresh_token": user.refreshToken
          },
          "provider": user.providerData[0].providerId
        };
        console.log(payload);
        await fetch(createUserURL, {
          method: "POST",
          headers: {"content-type": "application/json"},
          body: JSON.stringify(payload)
        })
        .catch((err)=>{
          console.log(err);
          reject();
        });
        resolve();
      })
    }

    authChangedEventListener(){
      return new Promise((resolve)=>{
        FirebaseAuth().onAuthStateChanged(async (user) => {
          console.log("User Auth Changed");
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            console.log("User Signed In");
            await this.setUserState(user);
            resolve();
            // ...
          } else {
              console.log("User Signed Out");
              await this.setUserState();
              resolve();
          }
        });
      })
    };

    setUserState(user=null){
      return new Promise(async (resolve)=>{
        if(user){
          await this.createUserIfRequired(user);
          this.setState({
            isSignedIn: true, 
            currentUserUid: user.uid, 
            currentUserDisplayName: user.displayName, 
            currentUserEmail: user.email,
            currentUserPhotoURL: user.photoURL
          }, ()=>{
            resolve();
          });
        }
        else{
          this.setState({
            isSignedIn: false, 
            currentUserUid: null, 
            currentUserDisplayName: null, 
            currentUserEmail: null,
            currentUserPhotoURL: null
          }, ()=>{
            resolve();
          });
        }
      })
    }

    checkIfUserIsSignedIn() {
      return new Promise(async (resolve)=>{
        let signedInUser = FirebaseAuth().currentUser;
        if(signedInUser){
            let {uid, email} = signedInUser;
            await this.setUserState(signedInUser);
            resolve();
        }
        else{
            // this.setState({isSignedIn: false});
            await this.setUserState();
            resolve();
        }
      })
    }

    componentDidMount(){
      // this.getProductIdAndPriceIdFromURL();
      // this.checkIfUserIsSignedIn();
      this.load();
    }

    render(){
        return(
            // Check if the user is signed in
            <React.Fragment>
              <NavBar/>
              {this.state.loadingComplete && 
                <div style={{marginTop: "50px", marginLeft: "5px", marginRight: "5px"}}>
                  {this.state.isPageValid && 
                    <div>
                      {this.state.isSignedIn &&
                        <div>
                          {/* <center><h3>Hang tight!</h3></center>
                          <center> Redirecting you to Payment Gateway </center> */}
                          <center>
                            <span>
                              <img src={this.state.currentUserPhotoURL} style={{borderRadius: 100, width: 80, height: 80}}></img>
                              <div style={{margin: "20px"}}>
                                <h4>Welcome {this.state.currentUserDisplayName}</h4>
                              </div>
                              
                              <h3>{this.state.productName}</h3>
                            </span>
                          </center>
                          
                          <center>
                            <Button disabled={this.state.paymentLoading} variant="primary" onClick={this.getCheckoutSession.bind(this)} style={{padding: 10, fontWeight: 'bold', margin: 20}}>Proceed to Payment Gateway</Button>
                          </center>
                          <center style={{margin: 20}}>
                            <a href="#" onClick={this.signOut}>Not {this.state.currentUserEmail}? Log Out</a>
                          </center>
                        </div>
                      }
                      {this.state.isSignedIn ||
                        <div style={{margin: "20px"}}>
                          <center>
                            <h5 style={{lineHeight: "2"}}>Please Sign in to continue with the payment of</h5>
                            <h4 style={{margin: "30px", lineHeight: "1.5"}}>{this.state.productName}</h4>
                          </center>
                          <center>
                            <img alt="SignIn With Google" src={signInLogo} style={{width: "12rem", height: "40%"}} onClick={this.signIn}></img>
                          </center>
                        </div>
                      }
                    </div>
                  }
                  {this.state.isPageValid ||
                    <div>
                      <center>
                        <h3 style={{margin: "15px", lineHeight: 1.5}}>Oops! Link is not valid</h3>
                        <h4 style={{margin: "15px", lineHeight: 1.5}}>Please Select a Product <a href="http://nestria.org">here</a> to continue</h4>

                        <p style={{margin: "15px", lineHeight: 1.5}}>If you are testing the app, click the Link below to simulate the purchase flow.</p>
                        <a href="/?id=price_1I8QHc4nFcF7CmtPvpR0i8JF">Nestria Membership</a>
                      </center>
                    </div>
                  }
                </div>
              }
              {this.state.loadingComplete ||
                <center style={{marginTop: '100px'}}>
                  <ClipLoader/>
                </center>
              }
          </React.Fragment>
          );
    }
}

export {Checkout};