import { Col, Row, Form } from "react-bootstrap";

const BillingDetailsFields = () => {
  return (<>
    <Form.Group controlId="userFullName">
      <Form.Label>Full Name</Form.Label>
      <Form.Control placeholder="John Doe" name="name" />
    </Form.Group>
    <Form.Group controlId="userEmailAddress">
      <Form.Label>Email Address</Form.Label>
      <Form.Control type="email" placeholder="user@example.com" name="email" />
    </Form.Group>
    <Row>
      <Col xs={5}>
        <Form.Group controlId="userCity">
          <Form.Label>City</Form.Label>
          <Form.Control placeholder="City" name="city" />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="userState">
          <Form.Label>State</Form.Label>
          <Form.Control placeholder="State" name="state" />
        </Form.Group>
      </Col>
      <Col xs={3}>
        <Form.Group controlId="userZip">
          <Form.Label>Zip Code</Form.Label>
          <Form.Control type="number" placeholder="10001" name="zip" />
        </Form.Group>
      </Col>
    </Row>

  </>
  );
};

export default BillingDetailsFields;
