const config = {
    "CURRENT_ENVIRONMENT": "PROD", // DEV | PROD
    "DEV": {
        "BACKEND_URL_ROOT": "http://localhost:5001/nestria-d07e4/us-central1/nestria",
        "CREATE_USER_IF_REQUIRED_ENDPOINT": "/create-user",
        "CREATE_CHECKOUT_SESSION_ENDPOINT": "/payments/create-checkout-session",
        "VERIFY_PAYMENT_METHOD_ENDPOINT": "/payments/verify-payment-method",
        "PAYMENT_METHOD_STATUS": "/payments/verify-payment-method/status",
        "GET_PRODUCT_BY_PRICE_ID_ENDPOINT": "/productByPriceId",
        "STRIPE": {
            "PUBLISHABLE_KEY": "pk_test_4Spq514S6WZpbxWY0n2uts0q"
        }
    },
    "PROD": {
        "BACKEND_URL_ROOT": "https://us-central1-nestria-d07e4.cloudfunctions.net/nestria",
        "CREATE_USER_IF_REQUIRED_ENDPOINT": "/create-user",
        "CREATE_CHECKOUT_SESSION_ENDPOINT": "/payments/create-checkout-session",
        "VERIFY_PAYMENT_METHOD_ENDPOINT": "/payments/verify-payment-method",
        "PAYMENT_METHOD_STATUS": "/payments/verify-payment-method/status",
        "GET_PRODUCT_BY_PRICE_ID_ENDPOINT": "/productByPriceId",
        "STRIPE": {
            "PUBLISHABLE_KEY": "pk_live_4SpqC7AKJjZMZEEOFJRdkDRS"
        }
    }
};

export { config };