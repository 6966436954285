import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavBar } from '../components/navbar';
import { FirebaseAuth, Provider } from '../components/instances/firebaseAuth';
import signInLogo from '../images/btn_google_signin_dark_normal_web@2x.png';
const { currentConfig } = require('../components/instances/currentConfig');


const SignIn = () => {

    const history = useHistory();

    const signIn = () => {
        FirebaseAuth().signInWithPopup(Provider)
            .then(async (user) => {
                await createUserIfRequired(user).catch(err => {
                    console.error(err);
                });
                history.push("/");
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const createUserIfRequired = (user) => {
        return new Promise(async (resolve, reject) => {
            let createUserURL = currentConfig.BACKEND_URL_ROOT + currentConfig.CREATE_USER_IF_REQUIRED_ENDPOINT;
            let payload = {
                "uid": user.user.uid,
                "email_address": user.user.email,
                "display_name": user.user.displayName,
                "photo_url": user.user.photoURL,
                "tokens": {
                    "access_token": await user.user.getIdToken(),
                    "refresh_token": user.user.refreshToken
                },
                "provider": user.user.providerData[0].providerId
            };

            await fetch(createUserURL, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(payload)
            })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
            resolve();
        })
    }

    return (
        <React.Fragment>
            <NavBar />
            <div style={{ margin: "20px" }}>
                <center>
                    <h5 style={{ lineHeight: "2" }}>Please Sign in to continue.</h5>
                </center>
                <center>
                    <img alt="SignIn With Google" src={signInLogo} style={{ width: "12rem", height: "40%" }} onClick={signIn}></img>
                </center>
            </div>


        </React.Fragment>
    )
}

export default SignIn;