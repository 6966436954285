import React from 'react';
import {NavBar} from '../components/navbar';

class PaymentCanceled extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <React.Fragment>
                <NavBar/>
                <div style={{marginTop: "50px", marginRight: "10px", marginLeft: "10px"}}>
                    <center>
                        <h2>Payment Canceled</h2>
                        <p style={{lineHeight: "2", fontSize: 18}}>Your payment is Canceled.</p>
                        <p style={{lineHeight: "2", fontSize: 18}}>Feel free to email us on <a href="mailto:kai@nestria.org">kai@nestria.org</a> if you have any questions regarding Nestria.</p>
                    </center>
                </div>
            </React.Fragment>
        )
    }
}

export {PaymentCanceled}