
import {firebase} from '@firebase/app';
import 'firebase/auth';
const {firebaseConfig} = require('../config/firebaseConfig');
firebase.initializeApp(firebaseConfig);
var Provider = new firebase.auth.GoogleAuthProvider();
var FirebaseAuth = firebase.auth;

export {FirebaseAuth, Provider};



