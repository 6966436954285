import React from 'react';
import {NavBar} from '../components/navbar';
import {FirebaseAuth, Provider} from '../components/instances/firebaseAuth';
import {ClipLoader} from "react-spinners";
class PaymentSuccess extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentUser: null,
            loadingComplete: false
        }
    }

    componentDidMount(){
        this.authChangedEventListener();
    }

    authChangedEventListener(){
        return new Promise((resolve)=>{
          FirebaseAuth().onAuthStateChanged(async (user) => {
            console.log("User Auth Changed");
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              console.log("User Signed In");
              await this.setState({currentUser: user, loadingComplete: true});
              resolve();
              // ...
            } else {
                console.log("User Signed Out");
                await this.setState({currentUser: null, loadingComplete: true});
                resolve();
            }
          });
        })
    };
      
    render(){
        return(
            <React.Fragment>
                <NavBar/>
                {this.state.loadingComplete &&
                    <div style={{marginTop: "50px", marginLeft: "10px", marginRight: "10px"}}>
                        <center>
                            <h2>Thank You</h2>
                            <p style={{lineHeight: "2", fontSize: 18}}>Your payment is complete.</p>
                            <p style={{lineHeight: "2", fontSize: 18}}>Expect an email at  <strong>{this.state.currentUser.email}</strong> shortly from Nestria staff to schedule your initial planning meeting.</p>
                            <p style={{lineHeight: "2", fontSize: 18}}>If you don't receive this within 24 hours, please check your spam folder or let us know at <a href="mailto:kai@nestria.org">kai@nestria.org</a>.</p>
                        </center>
                    </div>
                }
                {this.state.loadingComplete || 
                    <div style={{marginTop: "50px"}}>
                        <center>
                            <ClipLoader/>
                        </center>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export {PaymentSuccess}