import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Button, Alert } from "react-bootstrap";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BillingDetailsFields from '../components/billingDetailsField';


// currentUser is th euser object. Can be used to show a personalised checkout form.
const VerifyPaymentMethod = ({ currentUser, client_secret }) => {
    const [isProcessing, setProcessingTo] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [checkoutError, setCheckoutError] = useState();

    const elements = useElements();
    const stripe = useStripe();
    const history = useHistory();


    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    const handleFormSubmit = async ev => {
        ev.preventDefault();

        const billingDetails = {
            name: ev.target.name.value,
            email: ev.target.email.value,
            address: {
                city: ev.target.city.value,
                state: ev.target.state.value,
                postal_code: ev.target.zip.value
            }
        };

        setProcessingTo(true);

        const cardElement = elements.getElement("card");

        try {
            const { error } = await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: billingDetails
                }
            });

            if (error) {
                setCheckoutError(error.message);
                setProcessingTo(false);
                return;

            } else {
                // Successful Transaction.
                console.log("Success!")
                history.push('/payment-methods/success');
            }
        } catch (err) {
            setCheckoutError(err.message);
            history.push('/payment-methods/canceled');
        }
    };


    // stripe.com/docs/js
    // Controls the behavior of card element.
    const cardElementOpts = {
        style: {
            base: {
                fontSize: "18px",
                color: "#4a4645",
                "::placeholder": {
                    color: "#706d6c"
                }
            },
            invalid: {
                color: "#4a4645",
                iconColor: "#fc5000"
            }
        },
        hidePostalCode: true
    };

    return (
        <React.Fragment>
            <Container>
                <Form onSubmit={handleFormSubmit}>
                    <BillingDetailsFields />
                    <Form.Label>Card Details</Form.Label>
                    <CardElement
                        options={cardElementOpts}
                        onChange={handleCardDetailsChange}
                    />
                    {/* Displaying checkout error. */}
                    {checkoutError && <Alert variant="danger">{checkoutError}</Alert>}
                    <center>
                        <Button disabled={paymentLoading} variant="primary" type="submit" style={{ padding: 10, fontWeight: 'bold', margin: 20 }}>
                            Verify Payment Method
                    </Button>
                    </center>
                </Form>
            </Container>
        </React.Fragment>
    )

}

export default VerifyPaymentMethod;