import React from 'react';

class NavBar extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <React.Fragment>
                <div style={{margin: "20px"}}>
                    <a href="http://nestria.org" target="_blank" style={{textDecoration: 'none'}}>
                        <h3>Nestria</h3>
                    </a>
                    <hr style={{color: "grey"}}/>
                </div>
            </React.Fragment>
        )
    }
}

export {NavBar}