import React from 'react';
import { Container, Image, Button } from "react-bootstrap";

const InQueue = ({ user }) => {

    return (
        <React.Fragment>
            <center>
                <Container>
                    <Image src={user.photoURL} roundedCircle />
                </Container>
                <br></br>
                <p style={{ lineHeight: "2", fontSize: 18 }}>
                    Hello {user.displayName}, you've already verified, we'll contact you once your membership is ready to start.
                    </p>
                <p style={{ lineHeight: "2", fontSize: 18 }}>
                    If you have any queries, please contact us at <a href="mailto:kai@nestria.org">kai@nestria.org</a>.</p>
            </center>
            <center>
            <Button href="https://nestria.org" >
                Go To Home Page
            </Button>
            </center>

        </React.Fragment >
    )
}

export default InQueue;