import React from 'react';
import { NavBar } from '../components/navbar';

class PaymentMethodCanceled extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <NavBar />
                <div style={{ marginTop: "50px", marginRight: "10px", marginLeft: "10px" }}>
                    <center>
                        <h2>Could not verify your payment method.</h2>
                        <p style={{ lineHeight: "2", fontSize: 18 }}>Your verification payment is canceled. Please try again.</p>
                        <p style={{ lineHeight: "2", fontSize: 18 }}>If you are facing issues with payment, kindly email us on <a href="mailto:kai@nestria.org">kai@nestria.org</a>.</p>
                    </center>
                </div>
            </React.Fragment>
        )
    }
}

export { PaymentMethodCanceled }