import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NavBar } from '../components/navbar'
import VerifyPaymentMethod from '../components/verifyPaymentMethod';
import { FirebaseAuth, Provider } from '../components/instances/firebaseAuth';
import { ClipLoader } from "react-spinners";
import { Button } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InQueue from '../components/inQueue';

const { currentConfig } = require('../components/instances/currentConfig');
const stripePromise = loadStripe(currentConfig.STRIPE.PUBLISHABLE_KEY);


const PaymentMethods = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loadedUser, setLoadedUser] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [paymentConsent, setPaymentConsent] = useState(false);
    const [intent, setIntent] = useState(null);
    const [paymentMethodVerified, setPaymentMethodVerified] = useState(null);

    const history = useHistory();

    const authChangedEventListener = () => {
        return new Promise((resolve) => {
            FirebaseAuth().onAuthStateChanged(async (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    setCurrentUser(user);
                    setLoadedUser(true);
                    // setLoadingComplete(true); // This state is set after paymentMethodVerified state is set.
                    resolve();
                    // ...
                } else {
                    history.push("/signin");
                    setCurrentUser(null);
                    setLoadingComplete(true);
                    resolve();
                }
            });
        })
    };

    const getPaymentMethodStatus = (emailAddress) => {
        return new Promise((resolve, reject) => {
            let url = currentConfig.BACKEND_URL_ROOT + currentConfig.PAYMENT_METHOD_STATUS;
            fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "email": emailAddress })
            }).then(async response => {
                const data = await response.json();
                resolve(data.paymentMethodStatus);
            }).catch(err => {
                console.error(err);
                reject(err);
            })

        })
    };


    // Gets the client secret from payment intent.
    const getPaymentIntent = async () => {
        let emailAddress = currentUser.email;
        //Get Stripe.js instance
        // const stripe = await stripePromise;

        const verify_payment_method_url = currentConfig.BACKEND_URL_ROOT + currentConfig.VERIFY_PAYMENT_METHOD_ENDPOINT
        // Call back to verify payment method.
        fetch(verify_payment_method_url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "emailAddress": emailAddress })
        }).then(async response => {
            const intent = await response.json();
            if (intent.status === "success") {
                // Set client secret
                setIntent(intent);
            }
        }).catch(err => {
            console.error(err);
        })

    };

    const acceptConsent = async () => {
        setPaymentConsent(!paymentConsent);
        await getPaymentIntent();

    };

    authChangedEventListener();

    // Check if the user has already verified the payment method.
    useEffect(async () => {
        if (currentUser != null) {
            // The value will either be a true or false.
            let paymentVerifiedStatus = await getPaymentMethodStatus(currentUser.email).catch(err => {
                console.error(err);
            });
            setPaymentMethodVerified(paymentVerifiedStatus);
            setLoadingComplete(true);
        }
    }, [loadedUser])


    return (
        <React.Fragment>
            <Elements stripe={stripePromise}>
                <NavBar />
                {loadingComplete && !paymentConsent && !paymentMethodVerified &&
                    <div style={{ marginTop: "50px", marginLeft: "10px", marginRight: "10px" }}>

                        <center>
                            <span>
                                <img src={currentUser.photoURL} style={{ borderRadius: 100, width: 80, height: 80 }}></img>
                                <div style={{ margin: "20px" }}>
                                    <h4>Welcome {currentUser.displayName}</h4>
                                </div>
                            </span>
                        </center>

                        <center>
                            <p style={{ lineHeight: "2", fontSize: 18 }}>Please verify your payment method to continue.</p>
                            <p style={{ lineHeight: "2", fontSize: 18 }}> An amount of $1 will be deducted from your account. It will be refunded in 1-2 hours.</p>
                        </center>

                        <center>
                            <Button variant="primary" onClick={acceptConsent} style={{ padding: 10, fontWeight: 'bold', margin: 20 }}>Verify Payment Method</Button>
                        </center>
                    </div>
                }

                {paymentMethodVerified &&
                    <InQueue user={currentUser} />
                }

                {intent &&
                    <VerifyPaymentMethod currentUser={currentUser} client_secret={intent.client_secret} />
                }
                {loadingComplete ||
                    <div style={{ marginTop: "50px" }}>
                        <center>
                            <ClipLoader />
                        </center>
                    </div>
                }

            </Elements>
        </React.Fragment>
    )

}

export default PaymentMethods;