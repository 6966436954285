import logo from './logo.svg';
import './App.css';
import { Checkout } from './routes/checkout';
import { PaymentSuccess } from './routes/paymentSuccess';
import { PaymentCanceled } from './routes/paymentCancelled';
import { PaymentMethodSuccess } from './routes/paymentMethodSuccess';
import { PaymentMethodCanceled } from './routes/paymentMethodCanceled';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentMethods from './routes/paymentMethods';
import SignIn from './routes/signIn';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {/* <Checkout /> */}
          <PaymentMethods />
        </Route>
        <Route exact path="/payment-methods">
          <PaymentMethods />
        </Route>
        <Route exact path="/payment-methods/success">
          <PaymentMethodSuccess />
        </Route>
        <Route exact path="/payment-methods/canceled">
          <PaymentMethodCanceled />
        </Route>
        <Route exact path="/success">
          <PaymentSuccess />
        </Route>
        <Route exact path="/canceled">
          <PaymentCanceled />
        </Route>
        <Route exact path="/signin">
          <SignIn />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
