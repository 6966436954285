import React from 'react';
import { NavBar } from '../components/navbar';
import { FirebaseAuth, Provider } from '../components/instances/firebaseAuth';
import { ClipLoader } from "react-spinners";
class PaymentMethodSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            loadingComplete: false
        }
    }

    componentDidMount() {
        this.authChangedEventListener();
    }

    authChangedEventListener() {
        return new Promise((resolve) => {
            FirebaseAuth().onAuthStateChanged(async (user) => {
                console.log("User Auth Changed");
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    console.log("User Signed In");
                    await this.setState({ currentUser: user, loadingComplete: true });
                    resolve();
                    // ...
                } else {
                    console.log("User Signed Out");
                    await this.setState({ currentUser: null, loadingComplete: true });
                    resolve();
                }
            });
        })
    };

    render() {
        return (
            <React.Fragment>
                <NavBar />
                {this.state.loadingComplete &&
                    <div style={{ marginTop: "50px", marginLeft: "10px", marginRight: "10px" }}>
                        <center>
                            <h2>Congrats!</h2>
                            <p style={{ lineHeight: "2", fontSize: 18 }}>Your payment method is successfully verified.</p>
                            <p style={{ lineHeight: "2", fontSize: 18 }}>The amount will be refunded to your account in the next 2 hours.</p>
                            <p style={{ lineHeight: "2", fontSize: 18 }}>If you don't receive this within 24 hours, please let us know at <a href="mailto:kai@nestria.org">kai@nestria.org</a>.</p>
                        </center>
                    </div>
                }
                {this.state.loadingComplete ||
                    <div style={{ marginTop: "50px" }}>
                        <center>
                            <ClipLoader />
                        </center>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export { PaymentMethodSuccess }